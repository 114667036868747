/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

interface InputTextProps {
  labelText: string
  placeholder: string
  value: string
  type: string
  onChange: (x?: any) => void
  name?: string
  required?: boolean
  characterMax?: number
}

export const inputTextStyles =
  'pt-2.5 rounded block w-full border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue focus:border-transparent text-nptBase'

export const InputText: React.FunctionComponent<InputTextProps> = ({
  labelText,
  placeholder,
  value,
  type,
  onChange,
  name,
  required,
  characterMax,
}) => {
  return (
    <label className="block">
      <span className="block mb-1.5  text-sm md:text-nptBase">{labelText}</span>
      <input
        type={type}
        className={inputTextStyles}
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChange(event)}
        name={name ? name : ''}
        required={required ? required : false}
        maxLength={characterMax}
      />
    </label>
  )
}

interface TextAreaProps {
  labelText: string
  value: string
  onChange: (x?: any) => void
}

export const TextArea: React.FunctionComponent<TextAreaProps> = ({
  labelText,
  value,
  onChange,
}) => {
  return (
    <label>
      <span className="block mb-1.5 text-sm md:text-nptBase">{labelText}</span>
      <textarea
        className={inputTextStyles}
        rows={4}
        onChange={(event) => onChange(event)}
        value={value}
      />
    </label>
  )
}

interface InputDate {
  labelText: string
  onChange: (x?: any) => void
  value: string
  required?: boolean
}

export const InputDate: React.FunctionComponent<InputDate> = ({
  labelText,
  onChange,
  required,
  value,
}) => {
  return (
    <label className="block">
      <span className="block mb-1.5 text-sm md:text-nptBase">{labelText}</span>
      <input
        type="date"
        className={inputTextStyles}
        onChange={(event) => onChange(event)}
        required={required ? required : false}
        value={value}
      />
    </label>
  )
}

interface SelectOptions {
  labelText: string
  value: string
  onChange: (x?: any) => void
  options: {
    text: string
    value: string
  }[]
  showDefaultOption?: boolean
  placeholder?: string
}

export const SelectOptions: React.FunctionComponent<SelectOptions> = ({
  labelText,
  value,
  onChange,
  options,
  showDefaultOption = false,
  placeholder = 'Select',
}) => {
  return (
    <label className="block">
      <span className="block mb-1.5 text-sm md:text-nptBase">{labelText}</span>
      <select
        className={inputTextStyles}
        onChange={(event) => onChange(event.target.value)}
        value={value}
      >
        <option
          value=""
          disabled={showDefaultOption}
          hidden={!showDefaultOption}
        >
          {placeholder}
        </option>
        {options.map((item, i) => (
          <option key={`Option_${i}`} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    </label>
  )
}

export const inputCheckboxStyles =
  '-mt-1 rounded place-self-center rounded border-gray-300 focus:border-blue focus:none focus:ring-offset-0 focus:ring-blue focus:ring-opacity-50'

interface InputCheckbox {
  additionalClasses?: string
  labelText: string
  onChange: (x?: any) => void
}

export const InputCheckbox: React.FunctionComponent<InputCheckbox> = ({
  additionalClasses = '',
  labelText,
  onChange,
}) => {
  return (
    <label className="flex items-center space-x-2">
      <input
        type="checkbox"
        className={`${inputCheckboxStyles} ${additionalClasses}`}
        onChange={() => onChange()}
        readOnly
      />
      <span className="text-sm md:text-nptBase font-ttHovesRegular">
        {labelText}
      </span>
    </label>
  )
}

interface InputRadio {
  labelText: string
  isChecked: boolean
  onClick: (x?: any) => void
  value: string
}

export const InputRadio: React.FunctionComponent<InputRadio> = ({
  labelText,
  isChecked,
  onClick,
}) => {
  return (
    <label className="flex space-x-2" onClick={() => onClick()}>
      <input
        type="checkbox"
        className={inputCheckboxStyles}
        checked={isChecked}
        readOnly
      />
      <span className="text-sm md:text-nptBase">{labelText}</span>
    </label>
  )
}
