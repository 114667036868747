import '../styles/globals.css'
import '../styles/fonts.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { AppProps } from 'next/app'
import React, { useEffect, useState } from 'react'

import {
  TrackingFormContext,
  defaultTrackingFormApiResponse,
} from '../components/context/TrackingFormContext'
import {
  SchedulePickupFormContext,
  defaultSchedulePickupFormApiResponse,
} from '../components/context/SchedulePickupFormContext'
import {
  VerifyZipDataFormContext,
  defaultVerifyZipDataFormApiResponse,
} from '../components/context/VerifyZipDataFormContext'
import {
  RateEstimateFormContext,
  defaultRateEstimateFormApiResponse,
} from '../components/context/RateEstimateFormContext'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'

import Layout from '../components/shared/Layout'
import { useRouter } from 'next/router'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { NavContext } from '@/components/context/NavContext'
import Script from 'next/script'

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID

function MyApp({ Component, pageProps }: AppProps<{ session: Session }>) {
  const [trackingFormApiResponse, setTrackingFormApiResponse] = useState(
    defaultTrackingFormApiResponse
  )
  const [schedulePickupFormApiResponse, setSchedulePickupFormApiResponse] =
    useState(defaultSchedulePickupFormApiResponse)
  const [verifyZipDataFormApiResponse, setVerifyZipDataFormApiResponse] =
    useState(defaultVerifyZipDataFormApiResponse)
  const [rateEstimateFormApiResponse, setRateEstimateFormApiResponse] =
    useState(defaultRateEstimateFormApiResponse)
  const [showApplyLink, setShowApplyLink] = useState(false)
  const [navOpen, setNavOpen] = useState(false)
  const [reportOpen, setReportOpen] = useState(false)
  const [loadingState, setLoadingState] = useState(false)
  const router = useRouter()

  useEffect(() => {
    if (router.asPath.includes('join-the-team')) {
      setShowApplyLink(true)
    } else {
      setShowApplyLink(false)
    }
  }, [router.asPath])

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <SessionProvider session={pageProps.session}>
      <Script
        async
        id="ga-tag"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <Script async id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </Script>

      <TrackingFormContext.Provider
        value={{
          trackingFormApiResponse: trackingFormApiResponse,
          setTrackingFormApiResponse: setTrackingFormApiResponse,
        }}
      >
        <SchedulePickupFormContext.Provider
          value={{
            schedulePickupFormApiResponse: schedulePickupFormApiResponse,
            setSchedulePickupFormApiResponse: setSchedulePickupFormApiResponse,
          }}
        >
          <VerifyZipDataFormContext.Provider
            value={{
              verifyZipDataFormApiResponse: verifyZipDataFormApiResponse,
              setVerifyZipDataFormApiResponse: setVerifyZipDataFormApiResponse,
            }}
          >
            <RateEstimateFormContext.Provider
              value={{
                rateEstimateFormApiResponse: rateEstimateFormApiResponse,
                setRateEstimateFormApiResponse: setRateEstimateFormApiResponse,
              }}
            >
              <NavContext.Provider
                value={{
                  navOpen,
                  setNavOpen,
                  reportOpen,
                  setReportOpen,
                  loadingState,
                  setLoadingState,
                }}
              >
                <Layout showApplyLink={showApplyLink}>
                  <Component {...pageProps} />
                </Layout>
              </NavContext.Provider>
            </RateEstimateFormContext.Provider>
          </VerifyZipDataFormContext.Provider>
        </SchedulePickupFormContext.Provider>
      </TrackingFormContext.Provider>
    </SessionProvider>
  )
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page"s `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext)

//   return { ...appProps }
// }

export default MyApp
