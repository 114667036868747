import React, { useState } from 'react'

export interface ApiResponseProps {
  logic: string
  status: string
  bolno: string
  deldt: string
  boldt: string
  shipper: string
  pono: string
  origin: string
  dest: string
  errors: string
  showhide: string
  bolermsg: string
  protype: string
  nppronumber: string
  intpronumber: string
  intransit: string
  emsgozp: string
  emsgdzp: string
}

interface TrackingFormContextProps {
  trackingFormApiResponse: ApiResponseProps
  setTrackingFormApiResponse: (trackingFormApiResponse: any) => void
}

export const defaultTrackingFormApiResponse = {
  logic: '',
  status: '',
  bolno: '',
  deldt: '',
  boldt: '',
  shipper: '',
  pono: '',
  origin: '',
  dest: '',
  errors: '',
  showhide: '',
  bolermsg: '',
  protype: '',
  nppronumber: '',
  intpronumber: '',
  intransit: '',
  emsgozp: '',
  emsgdzp: '',
}

export const TrackingFormContext =
  React.createContext<TrackingFormContextProps>({
    trackingFormApiResponse: defaultTrackingFormApiResponse,
    setTrackingFormApiResponse: (trackingFormApiResponse) => {
      return trackingFormApiResponse
    },
  })

interface TrackingFormProviderProps {
  children: React.ReactNode
}

export const TrackingFormProvider = ({
  children,
}: TrackingFormProviderProps) => {
  const [trackingFormApiResponse, setTrackingFormApiResponse] = useState(
    defaultTrackingFormApiResponse
  )

  return (
    <TrackingFormContext.Provider
      value={{ trackingFormApiResponse, setTrackingFormApiResponse }}
    >
      {children}
    </TrackingFormContext.Provider>
  )
}
