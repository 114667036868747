import { createContext, Dispatch, SetStateAction } from 'react'

interface ContextState {
  navOpen: boolean
  setNavOpen: Dispatch<SetStateAction<boolean>>
  reportOpen: boolean
  setReportOpen: Dispatch<SetStateAction<boolean>>
  loadingState: boolean
  setLoadingState: Dispatch<SetStateAction<boolean>>
}
const NavContext = createContext({} as ContextState)

export { NavContext }
