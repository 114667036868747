export const accountsSearch = async (query: string) => {
  try {
    const response = await fetch(`/api/internal-api/search-accounts/${query}`)

    if (!response || response.status !== 200) {
      throw new Error(`Error searching accounts`)
    }
    const { accounts } = await response.json()
    return accounts
  } catch (error) {
    console.log(error)
  }
}
