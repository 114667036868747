import React, { useState } from 'react'

export interface ApiResponseProps {
  emsgpud: string
  errpud: string
  errcmb: string
  errozp: string
  errdzp: string
  errwt1: string
  errwt2: string
  errwt3: string
  errwt4: string
  errwt5: string
  errcod: string
  errdis: string
  emsgozp: string
  emsgdzp: string
  pickupdate: string
  wozip5: string
  worgtel: string
  worgadd: string
  worgcty: string
  wmailtoorgnet: string
  worgnet: string
  wdzip5: string
  wdestel: string
  wdesadd: string
  wdescty: string
  wmailtodesnet: string
  wdesnet: string
  wwt1: string
  wcls1: string
  wrte1: string
  wchg1: string
  wwt2: string
  wcls2: string
  wrte2: string
  wchg2: string
  wwt3: string
  wcls3: string
  wrte3: string
  wchg3: string
  wwt4: string
  wcls4: string
  wrte4: string
  wchg4: string
  wwt5: string
  wcls5: string
  wrte5: string
  wchg5: string
  wdspaswt: string
  wdefwt: string
  wdefrte: string
  wdefchg: string
  origchgexists: string
  destchgexists: string
  accessorialchgexists: string
  wbblipu: string
  wbbrepu: string
  wbblifd: string
  wbbresi: string
  wbbinsi: string
  wbbnoti: string
  hazmaterialexists: string
  wbbhazm: string
  wbbcodf: string
  wdscpct: string
  wdscamt: string
  wfscpct: string
  wfscamt: string
  wsubwt: string
  wtotal: string
  logic: string
  jsontime: string
  mode: string
  company: string
  errors: string
}

interface RateEstimateFormContextProps {
  rateEstimateFormApiResponse: ApiResponseProps
  setRateEstimateFormApiResponse: (rateEstimateFormApiResponse: any) => void
}

export const defaultRateEstimateFormApiResponse = {
  emsgpud: '',
  errpud: '',
  errcmb: '',
  errozp: '',
  errdzp: '',
  errwt1: '',
  errwt2: '',
  errwt3: '',
  errwt4: '',
  errwt5: '',
  errcod: '',
  errdis: '',
  emsgozp: '',
  emsgdzp: '',
  pickupdate: '',
  wozip5: '',
  worgtel: '',
  worgadd: '',
  worgcty: '',
  wmailtoorgnet: '',
  worgnet: '',
  wdzip5: '',
  wdestel: '',
  wdesadd: '',
  wdescty: '',
  wmailtodesnet: '',
  wdesnet: '',
  wwt1: '',
  wcls1: '',
  wrte1: '',
  wchg1: '',
  wwt2: '',
  wcls2: '',
  wrte2: '',
  wchg2: '',
  wwt3: '',
  wcls3: '',
  wrte3: '',
  wchg3: '',
  wwt4: '',
  wcls4: '',
  wrte4: '',
  wchg4: '',
  wwt5: '',
  wcls5: '',
  wrte5: '',
  wchg5: '',
  wdspaswt: '',
  wdefwt: '',
  wdefrte: '',
  wdefchg: '',
  origchgexists: '',
  destchgexists: '',
  accessorialchgexists: '',
  wbblipu: '',
  wbbrepu: '',
  wbblifd: '',
  wbbresi: '',
  wbbinsi: '',
  wbbnoti: '',
  hazmaterialexists: '',
  wbbhazm: '',
  wbbcodf: '',
  wdscpct: '',
  wdscamt: '',
  wfscpct: '',
  wfscamt: '',
  wsubwt: '',
  wtotal: '',
  logic: '',
  jsontime: '',
  mode: '',
  company: '',
  errors: '',
}

export const RateEstimateFormContext =
  React.createContext<RateEstimateFormContextProps>({
    rateEstimateFormApiResponse: defaultRateEstimateFormApiResponse,
    setRateEstimateFormApiResponse: (rateEstimateFormApiResponse) => {
      return rateEstimateFormApiResponse
    },
  })

interface RateEstimateFormProviderProps {
  children: React.ReactNode
}

export const RateEstimateFormProvider = ({
  children,
}: RateEstimateFormProviderProps) => {
  const [rateEstimateFormApiResponse, setRateEstimateFormApiResponse] =
    useState(defaultRateEstimateFormApiResponse)

  return (
    <RateEstimateFormContext.Provider
      value={{ rateEstimateFormApiResponse, setRateEstimateFormApiResponse }}
    >
      {children}
    </RateEstimateFormContext.Provider>
  )
}
