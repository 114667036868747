import React from 'react'

interface SearchResultRowProps {
  name: string
  id: string
  legacyIds?: string[]
  clearSearch?: () => void
}

const SearchResultRow: React.FC<SearchResultRowProps> = ({
  name,
  id,
  legacyIds,
  clearSearch,
}) => {
  const shouldWrap = legacyIds && legacyIds.length > 5

  return (
    <a href={`/portal/admin/account/${id}`} onClick={clearSearch}>
      <li
        id={`search-result-${id}`}
        className={`search-result-row cursor-pointer transition-all duration-100 ease-in-out mx-auto rounded-md px-2 w-[97%] py-3 my-2 group  ${'hover:bg-nptBGreen'} min-h-10`}
      >
        <div className="items-center justify-between md:flex">
          <h3 className="flex-1 transition-all duration-100 ease-in-out group-hover:text-white font-regular">
            {name}
          </h3>
          <p className="flex items-center text-sm transition-all duration-100 ease-in-out group-hover:text-white">
            <span>ID: </span>
            <span
              className="overflow-hidden overflow-ellipsis w-[130px]"
              title={id}
            >
              {id}
            </span>
          </p>
        </div>
        <div className="flex justify-start mt-1 md:items-center gap-x-4">
          <p className="text-sm transition-all duration-100 ease-in-out group-hover:text-white">
            ID&apos;s:
          </p>
          <p className="w-full text-sm transition-all duration-100 ease-in-out group-hover:text-white">
            {legacyIds?.join(', ')}
          </p>
        </div>
      </li>
    </a>
  )
}

export default SearchResultRow
