import React, { useState } from 'react'

export interface ApiResponseProps {
  errpud: string
  errozp: string
  name: string
  nameer: string
  nameermsg: string
  phone: string
  phoneer: string
  phoneermsg: string
  email: string
  emailer: string
  emailermsg: string
  requestor: string
  requestordesc: string
  requestordescer: string
  reqdescermsg: string
  pudate: string
  pudateer: string
  pudateermsg: string
  pucompany: string
  pucompanyer: string
  pucompanyermsg: string
  pustreet: string
  pustreeter: string
  pustreetermsg: string
  pucity: string
  pucityer: string
  pucityermsg: string
  pust: string
  puster: string
  pustermsg: string
  puzip: string
  puziper: string
  puzipermsg: string
  destcity: string
  destcityer: string
  destcityermsg: string
  destst: string
  destster: string
  deststermsg: string
  nopallets: string
  nopalletser: string
  nopalletsermsg: string
  paldesc: string
  paldescer: string
  paldescermsg: string
  approxwt: string
  approxwter: string
  approxwtermsg: string
  timeavail: string
  timeavailer: string
  timeavailermsg: string
  timeavailampm: string
  timeclose: string
  timecloseer: string
  timecloseermsg: string
  timecloseampm: string
  descoffrt: string
  specialinstr: string
  logic: string
  jsontime: string
  mode: string
  company: string
  errors: string
}

interface SchedulePickupFormContextProps {
  schedulePickupFormApiResponse: ApiResponseProps
  setSchedulePickupFormApiResponse: (schedulePickupFormApiResponse: any) => void
}

export const defaultSchedulePickupFormApiResponse = {
  errpud: '',
  errozp: '',
  name: '',
  nameer: '',
  nameermsg: '',
  phone: '',
  phoneer: '',
  phoneermsg: '',
  email: '',
  emailer: '',
  emailermsg: '',
  requestor: '',
  requestordesc: '',
  requestordescer: '',
  reqdescermsg: '',
  pudate: '',
  pudateer: '',
  pudateermsg: '',
  pucompany: '',
  pucompanyer: '',
  pucompanyermsg: '',
  pustreet: '',
  pustreeter: '',
  pustreetermsg: '',
  pucity: '',
  pucityer: '',
  pucityermsg: '',
  pust: '',
  puster: '',
  pustermsg: '',
  puzip: '',
  puziper: '',
  puzipermsg: '',
  destcity: '',
  destcityer: '',
  destcityermsg: '',
  destst: '',
  destster: '',
  deststermsg: '',
  nopallets: '',
  nopalletser: '',
  nopalletsermsg: '',
  paldesc: '',
  paldescer: '',
  paldescermsg: '',
  approxwt: '',
  approxwter: '',
  approxwtermsg: '',
  timeavail: '',
  timeavailer: '',
  timeavailermsg: '',
  timeavailampm: '',
  timeclose: '',
  timecloseer: '',
  timecloseermsg: '',
  timecloseampm: '',
  descoffrt: '',
  specialinstr: '',
  logic: '',
  jsontime: '',
  mode: '',
  company: '',
  errors: '',
}

export const SchedulePickupFormContext =
  React.createContext<SchedulePickupFormContextProps>({
    schedulePickupFormApiResponse: defaultSchedulePickupFormApiResponse,
    setSchedulePickupFormApiResponse: (schedulePickupFormApiResponse) => {
      return schedulePickupFormApiResponse
    },
  })

interface SchedulePickupFormProviderProps {
  children: React.ReactNode
}

export const SchedulePickupFormProvider = ({
  children,
}: SchedulePickupFormProviderProps) => {
  const [schedulePickupFormApiResponse, setSchedulePickupFormApiResponse] =
    useState(defaultSchedulePickupFormApiResponse)

  return (
    <SchedulePickupFormContext.Provider
      value={{
        schedulePickupFormApiResponse,
        setSchedulePickupFormApiResponse,
      }}
    >
      {children}
    </SchedulePickupFormContext.Provider>
  )
}
