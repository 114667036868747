import Link from 'next/link'
import svg from './NPTLogo'

interface LogoProps {
  additionalClasses?: string
  marginVertical?: string
  height?: string
  width?: string
}

const Logo = ({
  additionalClasses = '',
  height = 'h-[45px]',
  marginVertical = '',
  width = 'w-[82px]',
}: LogoProps) => (
  <div className="flex justify-center items-center w-full lg:w-auto">
    <Link href="/">
      <span className="sr-only">North Park Transportation</span>
      <svg xmlns="http://www.w3.org/2000/svg" className={`${height} ${width}`}>
        <title>North Park Transportation Company</title>
        <path
          d="M 0 2.459 C 0 1.354 0.895 0.459 2 0.459 L 80 0.459 C 81.105 0.459 82 1.354 82 2.459 L 82 42.459 C 82 43.563 81.105 44.459 80 44.459 L 2 44.459 C 0.895 44.459 0 43.563 0 42.459 Z"
          fill="rgb(45,62,67)"
        />
        <path
          d="M 28.193 9.383 L 23.361 20.498 L 23.361 9.383 L 17.245 9.383 L 6.502 34.056 L 12.594 34.056 L 17.189 23.721 L 17.189 34.056 L 23.698 34.056 L 34.44 9.383 Z"
          fill="rgb(255,255,255)"
        />
        <path
          d="M 12.594 34.056 L 6.502 34.056 L 7.219 35.382 L 13.311 35.382 L 17.189 26.867 L 17.189 23.721 Z M 23.356 9.383 L 24.469 11.435 L 24.629 17.58 L 23.361 20.498 Z M 34.435 9.383 L 23.698 34.056 L 17.189 34.056 L 17.905 35.382 L 24.409 35.382 L 35.152 10.709 Z M 52.662 10.39 C 52.273 10.019 51.813 9.785 51.386 9.637 C 52.23 10.046 52.813 10.91 52.813 11.908 C 52.813 12.184 52.778 12.45 52.695 12.7 L 46.714 26.077 C 46.262 26.768 45.48 27.225 44.59 27.225 L 37.045 27.225 L 34.057 34.057 L 27.882 34.057 L 28.611 35.386 L 34.703 35.386 L 37.725 28.545 L 45.044 28.545 C 45.044 28.545 47.095 28.654 48.094 26.348 C 48.927 24.427 52.018 17.356 53.029 15.043 L 53.351 14.306 C 53.351 14.306 53.402 14.148 53.444 13.899 C 53.531 13.464 53.568 13.072 53.568 12.717 C 53.58 12.612 53.587 12.505 53.587 12.397 C 53.587 11.594 53.228 10.875 52.662 10.39 Z"
          fill="rgb(235,55,38)"
        />
        <path
          d="M 50.278 9.383 L 38.625 9.383 L 27.882 34.056 L 34.057 34.056 L 37.045 27.224 L 44.59 27.224 C 45.48 27.224 46.263 26.767 46.714 26.076 L 52.695 12.699 C 52.778 12.45 52.813 12.184 52.813 11.907 C 52.813 10.513 51.678 9.383 50.278 9.383 Z M 44.821 16.56 L 43.004 20.579 C 42.864 20.793 42.626 20.921 42.371 20.921 L 39.758 20.921 L 42.088 15.572 L 44.1 15.572 C 44.517 15.572 44.855 15.908 44.855 16.324 C 44.855 16.406 44.845 16.486 44.821 16.56 Z"
          fill="rgb(255,255,255)"
        />
        <path
          d="M 44.332 17.641 L 44.82 16.56 C 44.845 16.486 44.854 16.407 44.854 16.324 C 44.854 15.908 44.517 15.572 44.1 15.572 L 42.087 15.572 L 39.775 20.88 C 39.787 20.894 39.799 20.908 39.811 20.921 L 41.088 20.921 L 42.824 16.939 L 43.826 16.939 C 44.131 16.939 44.378 17.185 44.378 17.489 C 44.378 17.549 44.35 17.587 44.332 17.641 Z M 74.734 9.383 L 72.025 15.572 L 65.795 15.572 L 58.009 34.057 L 51.767 34.057 L 52.53 35.408 L 58.772 35.408 L 66.558 16.924 L 72.789 16.924 L 75.498 10.734 Z M 59.081 16.923 L 59.658 15.572 L 53.317 15.572 L 54.08 16.923 Z"
          fill="rgb(235,55,38)"
        />
        <path
          d="M 56.012 9.383 L 53.317 15.572 L 59.658 15.572 L 51.767 34.056 L 58.009 34.056 L 65.795 15.572 L 72.025 15.572 L 74.734 9.383 Z"
          fill="rgb(255,255,255)"
        />
      </svg>
    </Link>
  </div>
)
export default Logo
