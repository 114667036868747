import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import Footer from '../Footer'
import Header from '../Header'

interface LayoutProps {
  children: React.ReactNode
  showApplyLink?: boolean
}

const Layout: React.FC<LayoutProps> = ({ children, showApplyLink }) => {
  const { data: session, status } = useSession()
  const router = useRouter()

  useEffect(() => {
    if (session?.user?.status === 'reset_password') {
      router.push('/reset-password')
    }
  }, [])

  return (
    <div className="flex flex-col">
      <Header showApplyLink={showApplyLink} session={session} status={status} />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
