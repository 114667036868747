import React, { useState } from 'react'
import {
  InputText,
  inputCheckboxStyles,
  inputTextStyles,
} from './elements/Inputs'
import {
  ButtonFontSize,
  darkGreenButtonStyles,
  largePaddingStyles,
} from './elements/SubmitButton'

const ContactForm: React.FunctionComponent = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  return (
    <form
      name="contact"
      action="/"
      method="post"
      data-netlify="true"
      // netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <h1 className="mt-3 mb-6 text-sm leading-relaxed md:text-nptBase">
        Please fill out the information and we’ll get back to you right away.
      </h1>

      <div className="lg:flex lg:space-x-5 lg:mt-6">
        <div className="flex-1 mb-5">
          <InputText
            labelText="First Name"
            type="text"
            placeholder=""
            value={firstName}
            onChange={(event: {
              target: { value: React.SetStateAction<string> }
            }) => setFirstName(event.target.value)}
            required={true}
            name="firstName"
          />
        </div>

        <div className="flex-1 mb-5">
          <InputText
            labelText="Last Name"
            type="text"
            placeholder=""
            value={lastName}
            onChange={(event: {
              target: { value: React.SetStateAction<string> }
            }) => setLastName(event.target.value)}
            required={true}
            name="lastName"
          />
        </div>
      </div>
      <div className="lg:flex lg:space-x-5 lg:mb-6">
        <div className="flex-1 mb-5">
          <InputText
            labelText="Email"
            type="text"
            placeholder=""
            value={email}
            onChange={(event: {
              target: { value: React.SetStateAction<string> }
            }) => setEmail(event.target.value)}
            required={true}
            name="email"
          />
        </div>

        <div className="flex-1 mb-5">
          <InputText
            labelText="Phone"
            type="text"
            placeholder=""
            value={phone}
            onChange={(event: {
              target: { value: React.SetStateAction<string> }
            }) => setPhone(event.target.value)}
            name="phone"
          />
        </div>
      </div>

      <h2 className="text-sm md:text-nptBase">What can we help you with?</h2>

      <div className="py-5 md:-mb-1 md:flex md:space-x-5">
        <label className="flex space-x-2">
          <input
            type="checkbox"
            className={inputCheckboxStyles}
            value="Yes"
            name="salesInquiry"
          />
          <span className="text-sm md:text-nptBase">Sales Inquiry</span>
        </label>

        <label className="flex space-x-2">
          <input
            type="checkbox"
            className={inputCheckboxStyles}
            value="Yes"
            name="supportInquiry"
          />
          <span className="text-sm md:text-nptBase">Support Inquiry</span>
        </label>

        <label className="flex space-x-2">
          <input
            type="checkbox"
            className={inputCheckboxStyles}
            value="Yes"
            name="jobInquiry"
          />
          <span className="text-sm md:text-nptBase">Job Inquiry</span>
        </label>
      </div>

      <div className="mb-5 md:mt-6">
        <label>
          <span className="block mb-1.5 text-sm md:text-nptBase">Message</span>
          <textarea className={inputTextStyles} rows={8} name="message" />
        </label>
      </div>

      <label className="hidden">
        Don&apos;t fill this out if you&apos;re human:
        <input name="bot-field" />
      </label>

      <div className="text-right">
        <button
          className={`${darkGreenButtonStyles} ${largePaddingStyles} ${ButtonFontSize.large}`}
          type="submit"
        >
          Send
        </button>
      </div>
    </form>
  )
}

export default ContactForm
