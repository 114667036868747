export enum CognitoIdentityProviderErrorEnum {
  INVALID_PARAMETER = 'InvalidParameterException',
  CODE_DELIVERY_FAILURE = 'CodeDeliveryFailureException',
  CODE_MISMATCH = 'CodeMismatchException',
  EXPIRED_CODE = 'ExpiredCodeException',
  FORCE_CHANGE_PASSWORD = 'ForceChangePasswordException',
  INTERNAL_ERROR = 'InternalErrorException',
  INVALID_PASSWORD = 'InvalidPasswordException',
  LIMIT_EXCEEDED = 'LimitExceededException',
  NOT_AUTHORIZED = 'NotAuthorizedException',
  PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException',
  TOO_MANY_FAILED_ATTEMPTS = 'TooManyFailedAttemptsException',
  TOO_MANY_REQUESTS = 'TooManyRequestsException',
  USER_NOT_FOUND = 'UserNotFoundException',
  USERNAME_EXISTS = 'UsernameExistsException',
  ALIAS_EXISTS = 'AliasExistsException',
  MFA_SETUP_REQUIRED = 'MFASetupRequiredException',
  PASSWORD_POLICY_VIOLATION = 'PasswordPolicyViolationException',
  UNEXPECTED_ERROR = 'UnexpectedErrorException',
  USER_NOT_CONFIRMED = 'UserNotConfirmedException',
  USER_CREATION_ERROR = 'UserCreationError',
  EMAIL_DELIVERY_ERROR = 'EmailDeliveryError',
  GOOGLE_USER_NOT_FOUND = 'GoogleUserNotFoundException',
  GOOGLE_USER_NOT_VERIFIED = 'GoogleUserNotVerified',
  NOT_AUTHORIZED_ERROR = 'NotAuthorizedError', // Renamed to avoid conflict
  MIGRATED_USER_RESET = 'MigratedUserReset',
}

export const cognitoErrorsIndex = {
  [CognitoIdentityProviderErrorEnum.INVALID_PARAMETER]: 'Invalid parameter',
  [CognitoIdentityProviderErrorEnum.CODE_DELIVERY_FAILURE]:
    'Code delivery failure',
  [CognitoIdentityProviderErrorEnum.CODE_MISMATCH]: 'Code does not match',
  [CognitoIdentityProviderErrorEnum.EXPIRED_CODE]: 'Expired code',
  [CognitoIdentityProviderErrorEnum.FORCE_CHANGE_PASSWORD]:
    'Please check your email for a temporary password',
  [CognitoIdentityProviderErrorEnum.INTERNAL_ERROR]: 'Internal error',
  [CognitoIdentityProviderErrorEnum.INVALID_PASSWORD]: 'Invalid password',
  [CognitoIdentityProviderErrorEnum.LIMIT_EXCEEDED]:
    'You have exceeded the limited number of attempts. Please try again later.',
  [CognitoIdentityProviderErrorEnum.NOT_AUTHORIZED]:
    'This user is not authorized',
  [CognitoIdentityProviderErrorEnum.PASSWORD_RESET_REQUIRED]:
    'Password reset required',
  [CognitoIdentityProviderErrorEnum.TOO_MANY_FAILED_ATTEMPTS]:
    'Too many failed attempts. Please try again later.',
  [CognitoIdentityProviderErrorEnum.TOO_MANY_REQUESTS]:
    'Too many requests. Please try again later.',
  [CognitoIdentityProviderErrorEnum.USER_NOT_FOUND]:
    'An account with this email could not be found, please register and try again.',
  [CognitoIdentityProviderErrorEnum.USERNAME_EXISTS]:
    'An account with this email already exists',
  [CognitoIdentityProviderErrorEnum.ALIAS_EXISTS]: 'The email already exists',
  [CognitoIdentityProviderErrorEnum.MFA_SETUP_REQUIRED]: 'MFA setup required',
  [CognitoIdentityProviderErrorEnum.PASSWORD_POLICY_VIOLATION]:
    'Password policy violation',
  [CognitoIdentityProviderErrorEnum.UNEXPECTED_ERROR]: 'Unexpected error',
  [CognitoIdentityProviderErrorEnum.USER_NOT_CONFIRMED]: 'User not confirmed',
  [CognitoIdentityProviderErrorEnum.USER_CREATION_ERROR]:
    'Unable to create the user. Please try again later.',
  [CognitoIdentityProviderErrorEnum.EMAIL_DELIVERY_ERROR]:
    'Unable to send an email to that address.',
  [CognitoIdentityProviderErrorEnum.GOOGLE_USER_NOT_FOUND]:
    'Google user not found. Please register or use a different email.',
  [CognitoIdentityProviderErrorEnum.GOOGLE_USER_NOT_VERIFIED]:
    'A login using this Google email was already attempted. Please check your email and verify to continue.',
  [CognitoIdentityProviderErrorEnum.NOT_AUTHORIZED_ERROR]:
    'Incorrect email or password', // Renamed entry
  [CognitoIdentityProviderErrorEnum.MIGRATED_USER_RESET]: 'MigratedUserReset',
}

export enum UserMethodEnum {
  SUCCESS = 'success',
  FAILURE = 'failure',
  FORGOT_PASSWORD_PENDING = 'forgot_password_pending',
  CONFIRM_EMAIL = 'confirm_email',
  FORGOT_PASSWORD = 'forgot_password',
  RESET_PASSWORD = 'reset_password',
  RESET_PASSWORD_SUCCESS = 'reset-password-success',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}

export enum AccountStatusEnum {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
  PENDING = 'PENDING',
}

export enum CognitoAuthFlowEnum {
  ADMIN_USER_PASSWORD_AUTH = 'ADMIN_USER_PASSWORD_AUTH',
}

export enum UserRoleEnum {
  ADMIN = 'admin',
  User = 'user',
  NOPK_ADMIN = 'nopk-admin',
}
