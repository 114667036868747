import React from 'react'

interface Props {
  text: string
  onClick?: (x?: any) => void | any
  isDisabled: boolean
  fontSize?: ButtonFontSize
}

export enum ButtonFontSize {
  small = 'text-[0.875rem]',
  large = 'text-[1rem]',
}

export const greenButtonStyles =
  'block font-ttHovesMedium text-center whitespace-nowrap rounded duration-300 bg-green-success leading-bigTitle tracking-[.0015em] text-white hover:bg-green-classic focus:bg-green-classic focus:outline-none focus:border-transparent'
export const darkGreenButtonStyles =
  'block font-ttHovesMedium text-center whitespace-nowrap rounded-md duration-300 bg-green-classic leading-bigTitle tracking-[.0125em] text-white hover:bg-nptDGreen focus:bg-nptDGreen focus:outline-none focus:border-transparent'

export const smallPaddingStyles = 'py-[9px] px-4 w-max'
export const largePaddingStyles = 'py-3 px-10 w-max'

const SubmitButton: React.FunctionComponent<Props> = ({
  text,
  onClick,
  isDisabled,
  fontSize = ButtonFontSize.large,
}) => {
  return (
    <button
      type="submit"
      value="Submit"
      className={`${darkGreenButtonStyles} ${largePaddingStyles} ${fontSize} ${
        isDisabled
          ? 'bg-gray-400 hover:bg-gray-400 focus:bg-gray-400'
          : 'bg-nptClassic hover:bg-nptDGreen focus:bg-nptDGreen'
      }`}
      onClick={() => {
        !isDisabled && onClick ? onClick() : false
      }}
    >
      {text}
    </button>
  )
}

export default SubmitButton
