import React from 'react'

export enum ButtonTypeEnum {
  button = 'button',
  submit = 'submit',
}

export type ButtonLinkProps = {
  text: string
  isDisabled?: boolean
  onClickCallback: any
  icon?: JSX.Element
}

interface ButtonProps {
  borderRadius?: string
  children: React.ReactNode
  disabled?: boolean
  disableHoverShadow?: boolean
  type?: ButtonTypeEnum
  additionalStyles?: string
  color?: string
  hoverColor?: string
  id?: string
  buttonPosition?: string
  inline?: boolean
  width?: string
  borderColor?: string
  textColor?: string
  padding?: string
  border?: string
  font?: string
  fontSize?: string
  handleClick?: any
  onFocus?: any
  onBlur?: any
}

export type ButtonTwoProps = {
  text: string
  isDisabled?: boolean
  onClickCallback?: any
  onBlur?: any
  icon?: JSX.Element
  removeBorder?: boolean
  id?: string
  loading?: string
  smallText?: boolean
  isSecondaryDisabled?: boolean
  customStyles?: string
  dark?: boolean
  borderColor?: string
  padding?: string
  type?: ButtonTypeEnum
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled = false,
  type = ButtonTypeEnum.button,
  additionalStyles,
  color = 'bg-nptDGreen',
  hoverColor = 'hover:bg-green-success',
  id = '',
  buttonPosition = 'justify-end',
  inline = false,
  width = 'w-auto',
  borderRadius = 'rounded-md',
  borderColor = 'border-transparent',
  textColor = 'text-white',
  padding = 'px-8 py-2',
  border = 'border',
  font = 'font-ttHovesRegular',
  fontSize = 'text-base sm:text-sm',
  disableHoverShadow = false,
  handleClick,
  onFocus,
  onBlur,
}) => {
  if (inline) {
    return (
      <button
        id={id}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={handleClick}
        disabled={disabled}
        className={`${color} ${
          !disabled && hoverColor
        } ${borderRadius} ${padding} ${textColor} ${border} ${font} ${borderColor} ${fontSize} font-medium ${
          !disabled && !disableHoverShadow && 'shadow-sm'
        } focus:outline-none focus:ring-2 focus:ring-nptDGreen focus:ring-offset-2 ${width} ${
          disableHoverShadow ? '' : 'hover:shadow-lg'
        } transition-all duration-300 ${additionalStyles}`}
        type={type}
      >
        {children}
      </button>
    )
  }

  return (
    <div className={`flex w-full ${buttonPosition}`}>
      <button
        onClick={handleClick}
        disabled={disabled}
        className={`${color} ${
          !disabled && hoverColor
        } ${borderRadius} ${padding} ${textColor} ${border} ${font} ${borderColor} ${fontSize} font-medium  ${
          !disabled && !disableHoverShadow && 'shadow-sm'
        } focus:outline-none focus:ring-2 focus:ring-nptDGreen focus:ring-offset-2 sm:ml-3 ${width} ${
          disableHoverShadow ? '' : 'hover:shadow-lg'
        } transition-all duration-300 ${additionalStyles}`}
        type={type}
      >
        {children}
      </button>
    </div>
  )
}

export const getInnerButtonStyles = (smallText?: boolean) => {
  return ButtonTypeEnum.button
    ? `${smallText ? 'text-sm' : 'text-base'}  p-[10px]`
    : `block px-5 pb-3 pt-3.5 ${smallText ? 'text-sm' : 'text-base'} `
}
export const LegacyButton = ({
  text,
  onClickCallback,
  onBlur,
  isDisabled,
  icon,
  id,
  loading,
  smallText,
  customStyles,
  dark,
  type = ButtonTypeEnum.button,
}: ButtonTwoProps) => {
  return (
    <button
      id={id}
      disabled={isDisabled}
      type={type}
      className={`__Npt-Cta ${
        icon
          ? isDisabled
            ? '__Npt-Cta-light-disabled'
            : '__Npt-Cta-light'
          : dark
          ? isDisabled
            ? '__Npt-Cta-dark-disabled'
            : '__Npt-Cta-dark '
          : isDisabled
          ? '__Npt-Cta-light-disabled'
          : '__Npt-Cta-light'
      } items-center justify-center flex font-ttHovesMedium ${getInnerButtonStyles(
        smallText
      )} ${customStyles}`}
      onClick={
        onClickCallback
          ? (e: any) => {
              e.preventDefault()
              !isDisabled && onClickCallback()
            }
          : undefined
      }
      onBlur={onBlur ? onBlur : undefined}
    >
      {icon ? (
        <div className="flex flex-row items-center justify-center gap-[9.6px]">
          <span>{icon}</span>
          <p className="text-green whitespace-nowrap">
            {loading ? loading : text}
          </p>
        </div>
      ) : (
        <div className="relative flex flex-row items-center">
          <p className="whitespace-nowrap">{loading ? loading : text}</p>
        </div>
      )}
    </button>
  )
}

export const ButtonLink = ({
  isDisabled,
  text,
  icon,
  onClickCallback,
}: ButtonLinkProps) => {
  const content = (
    <>
      {icon ? (
        <div className="flex flex-row items-center justify-center gap-[9.6px]">
          {icon}
          <p className=" whitespace-nowrap">{text}</p>
        </div>
      ) : (
        <div className="relative flex flex-row items-center">
          <p className="whitespace-nowrap">{text}</p>
        </div>
      )}
    </>
  )

  return (
    <button
      disabled={isDisabled}
      className={`__Npt-Cta ${
        isDisabled ? '__Npt-Cta-link-disabled' : '__Npt-Cta-link '
      } items-center justify-center flex ${getInnerButtonStyles()}`}
      onClick={() => !isDisabled && onClickCallback()}
    >
      {content}
    </button>
  )
}
export default Button
