import React from 'react'
import { AccountSearchResultProps } from '@/libs/types'
import SearchResultRow from './SearchResultsRow'

interface AccountSearchResultsProps {
  searchResults: AccountSearchResultProps[]
  searchCharacterCount: number
  loading?: boolean
  isNavSearch?: boolean
  clearSearch?: () => void
}

const AccountSearchResults: React.FC<AccountSearchResultsProps> = ({
  searchCharacterCount,
  searchResults,
  loading,
  clearSearch,
  isNavSearch,
}) => {
  if (searchCharacterCount < 3) return <></>
  const renderNoResults = !loading && !searchResults?.length
  return (
    <ol
      id="search-results-container"
      className={`${
        isNavSearch
          ? 'mt-1 absolute left-0 right-0 w-full overflow-y-auto max-h-60 bg-white border border-gray-mediumLight border-t-0 rounded-b-md shadow-md z-10'
          : 'w-full overflow-hidden rounded-b search-results-container'
      }`}
    >
      {searchResults?.map((result) => (
        <SearchResultRow
          key={`SearchResults_${result.id}`}
          name={result.name}
          id={result.id}
          legacyIds={result.legacyID}
          clearSearch={clearSearch}
        />
      ))}

      {renderNoResults && (
        <li className="w-full p-4 text-center text-gray-500">
          No results found
        </li>
      )}

      {loading && !renderNoResults && (
        <li className="w-full p-4 text-center text-gray-500">Loading...</li>
      )}
    </ol>
  )
}

export default AccountSearchResults
