interface ExitIconProps {
  additionalClasses?: string
  height?: string
  width?: string
  onClickHandler?: () => any
}

export const ExitIcon = ({
  height = 'h-6',
  width = 'w-6',
  additionalClasses = '',
  onClickHandler,
}: ExitIconProps) => (
  <svg
    className={`${additionalClasses} ${height} ${width}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1"
    stroke="currentColor"
    onClick={onClickHandler}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
)
