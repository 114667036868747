import { TIMEOUT_MS } from '@/libs/constants'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

const retryAxiosInstance: AxiosInstance = axios.create({
  timeout: TIMEOUT_MS,
  headers: {
    'Content-Type': 'application/json',
  },
})

interface RetryConfig extends AxiosRequestConfig {
  retry: number
  retryDelay: number
}
const exponentialTime = (iterations: number) => {
  if (iterations < 0) {
    throw new Error('Iterations must be a non-negative integer')
  }
  const seconds = 2 ** iterations * 1000

  return seconds
}

const globalConfig: RetryConfig = {
  retry: 3, // retry 3 times
  retryDelay: 0, // Exponential backoff
}

retryAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, message } = error

    if (!config.retry) {
      return Promise.reject(error)
    }

    // retry while Network timeout or Network Error
    if (!(message.includes('timeout') || message.includes('Network Error'))) {
      return Promise.reject(error)
    }
    config.retryDelay = exponentialTime(globalConfig.retry - config.retry)
    config.retry -= 1

    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        console.log('retry the request', config.url)
        console.log(
          `Attempt ${globalConfig.retry - config.retry} of ${
            globalConfig.retry
          } Delay: ${(config.retryDelay / 1000) % 60} seconds`
        )
        resolve(error)
      }, config.retryDelay || 1000)
    })
    return delayRetryRequest.then(() => retryAxiosInstance(config))
  }
)
export { retryAxiosInstance, globalConfig }
