import { ExitIcon } from '@/components/icons/ExitIcon'
import SearchIcon from '@/components/icons/SearchIcon'
import React from 'react'

interface SearchFieldProps {
  searchQuery: string
  onChangeCallback: any
  inputPlaceholder: string
  clearSearch: () => void
  hasResults?: boolean
  isNavSearch?: boolean
}
const SearchField: React.FC<SearchFieldProps> = ({
  searchQuery,
  onChangeCallback,
  inputPlaceholder,
  clearSearch,
  isNavSearch,
}) => {
  return (
    <span className="relative">
      <input
        id="account-search-field"
        placeholder={inputPlaceholder}
        className="w-full pl-10 text-lg border-none rounded-md focus:ring-0 peer hide-clear-button"
        type="search"
        value={searchQuery}
        onChange={(event) => onChangeCallback(event.target.value)}
        autoComplete="off"
      />

      <SearchIcon
        height="h-4"
        width="w-4"
        additionalClasses="text-gray-medium peer-focus:text-green-mediumLight absolute left-3 top-[3px]"
      />
      {searchQuery && (
        <ExitIcon
          onClickHandler={() => clearSearch()}
          additionalClasses="h-4 w-4 cursor-pointer text-gray-medium absolute peer-focus:text-green-mediumLight right-3 top-[0px]"
        />
      )}
    </span>
  )
}

export default SearchField
