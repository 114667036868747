/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ReactNode,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { useLockBodyScroll } from '../hooks/useLockBodyScroll'

interface Props {
  children: ReactNode
  maxWidth: string
  setIsModalOpen: (x?: any) => void
  isModalOpen: boolean
  backgroundOpacity?: string
  persist?: boolean
  id?: string
}

const Modal: React.FunctionComponent<Props> = ({
  children,
  maxWidth,
  setIsModalOpen,
  isModalOpen,
  persist = false,
  backgroundOpacity = 'bg-opacity-80',
  id,
}) => {
  useLockBodyScroll()

  const modalFocusElement: any = useRef()
  const modalMaskRef: any = useRef()
  const [modalOpacity, setModalOpacity] = useState('opacity-0')

  const keyPress = useCallback(
    (event: any) => {
      if (event.key === 'Escape' && !persist) {
        setIsModalOpen(false)
      }
    },
    [setIsModalOpen]
  )

  useEffect(() => {
    const originalFocus: any = document.activeElement
    modalFocusElement.current.focus()
    document.addEventListener('keydown', keyPress)
    return () => {
      originalFocus.focus()
      document.removeEventListener('keydown', keyPress)
    }
  }, [keyPress])

  useEffect(() => {
    if (isModalOpen) {
      setModalOpacity('opacity-1')
    } else {
      setModalOpacity('opacity-0')
    }
  }, [])

  const closeModal = (event: any) => {
    modalMaskRef.current === event.target
      ? persist
        ? null
        : setIsModalOpen(false)
      : null
  }
  return (
    <section
      id={id}
      ref={modalMaskRef}
      onClick={closeModal}
      className={`fixed top-0 left-0 z-[60] w-screen h-screen transition ease-in-out bg-black bg-opacity-80 md:flex md:py-11 md:overflow-y-auto ${modalOpacity}`}
    >
      <div
        className={`transition duration-300 delay-300 ease-in-out relative overflow-y-auto bg-white ${maxWidth} m-auto w-full h-screen md:rounded-lg md:h-auto md:w-10/12 lg:flex ${modalOpacity}`}
      >
        <span tabIndex={0} ref={modalFocusElement} />
        {children}
        <span className="mozModalSpacer" />
      </div>
    </section>
  )
}
export default Modal
