import React, { useContext } from 'react'
import ContactForm from './forms/ContactForm'
import { attributes } from '../content/global-contact-modal.md'
import Modal from './shared/Modal'
import Image from 'next/image'
import { useBreakpoint } from 'use-breakpoint'
import { BREAKPOINTS } from '@/libs/constants'
import disableScroll from 'disable-scroll'

interface ContactModalProps {
  isContactModalOpen: boolean
  setIsContactModalOpen: (isContactModalOpen: boolean) => void
}

const ContactModal: React.FunctionComponent<ContactModalProps> = ({
  isContactModalOpen,
  setIsContactModalOpen,
}) => {
  const {
    operatingHours,
    contactEmail,
    addressLine1,
    addressLine2,
    phone,
    fax,
    linkedInUrl,
    youTubeUrl,
  } = attributes
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'XL')
  return (
    <Modal
      maxWidth="max-w-5xl"
      setIsModalOpen={setIsContactModalOpen}
      isModalOpen={isContactModalOpen}
    >
      <button
        className="absolute z-50 right-4 top-4"
        onClick={() => {
          breakpoint === 'S' || breakpoint === 'M' ? null : disableScroll.off()
          setIsContactModalOpen(!isContactModalOpen)
        }}
      >
        <span className="sr-only">Close Contact Information</span>
        <Image
          width="25"
          height="25"
          src="/images/mobile-close-icon.svg"
          alt="Close Contact Information"
          className="w-10 h-10"
        />
      </button>

      <div className="relative p-4 text-white bg-green-classic md:p-11">
        <h1 className="text-2xl leading-relaxed">Contact Information</h1>
        <p className="text-sm">{operatingHours}</p>
        <ul className="py-12">
          <li>
            <a
              className="flex items-center mb-4 space-x-5 md:mb-6 lg:space-x-8"
              href={`mailto:${contactEmail}`}
            >
              <Image
                width="25"
                height="25"
                src="/images/icon-email.png"
                alt="Email North Park Transportation"
                className="w-5"
              />
              <span className="text-sm font-ttHovesMedium">{contactEmail}</span>
            </a>
          </li>
          <li className="flex items-center mb-4 space-x-5 md:mb-6 lg:space-x-8">
            <Image
              width="25"
              height="25"
              src="/images/icon-place.png"
              alt="North Park Transportation Corporate Address"
              className="w-5"
            />
            <span className="text-sm font-ttHovesMedium">{addressLine1}</span>
          </li>
          <li className="flex items-center mb-4 space-x-5 md:mb-6 lg:space-x-8">
            <Image
              width="25"
              height="25"
              src="/images/icon-map.png"
              alt="North Park Transportation City and State"
              className="w-5"
            />
            <span className="text-sm font-ttHovesMedium">{addressLine2}</span>
          </li>
          <li>
            <a
              className="flex items-center mb-4 space-x-5 md:mb-6 lg:space-x-8"
              href={`tel:${phone}`}
            >
              <Image
                width="25"
                height="25"
                src="/images/icon-phone.png"
                alt="Call North Park Transportation"
                className="w-5"
              />
              <span className="text-sm font-ttHovesMedium">{phone}</span>
            </a>
          </li>
          <li>
            <a
              className="flex items-center mb-4 space-x-5 md:mb-6 lg:space-x-8"
              href={`tel:${fax}`}
            >
              <Image
                width="25"
                height="25"
                src="/images/icon-print.png"
                alt="Fax North Park Transportation"
                className="w-5"
              />
              <span className="text-sm font-ttHovesMedium">{fax}</span>
            </a>
          </li>
        </ul>
        <div className="absolute flex items-center space-x-5 bottom-4 lg:space-x-8 lg:bottom-11">
          <a href={linkedInUrl} target="_blank" rel="noreferrer">
            <Image
              width="25"
              height="25"
              src="/images/icon-linkedin.svg"
              alt="North Park Transportation on LinkedIn"
              className="w-6"
            />
          </a>
          <a href={youTubeUrl} target="_blank" rel="noreferrer">
            <Image
              width="25"
              height="25"
              src="/images/icon-youtube.svg"
              alt="North Park Transportation on YouTube"
              className="w-6"
            />
          </a>
        </div>
      </div>

      <div className="p-4 md:p-11 lg:flex-1">
        <ContactForm />
      </div>
    </Modal>
  )
}

export default ContactModal
