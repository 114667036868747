import Link from 'next/link'
import React, { useState } from 'react'
import Button from '../portal/ui/button'
import { signOut } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { UserRoleEnum } from '@/types/cognito'
import { NavContext } from '../context/NavContext'

interface AccountNavItemProps {
  timeOfDay?: string
}

const AccountNavItem: React.FC<AccountNavItemProps> = ({ timeOfDay }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [hidden, setHidden] = useState(true)
  const { status, data: session } = useSession()
  const { setReportOpen } = React.useContext(NavContext)

  const router = useRouter()
  const isPortal = router.pathname.includes('/portal')
  const isUserPortal = router.pathname.includes('/account')
  const isAdminPortal = router.pathname.includes('/admin')
  const isLogin = router.pathname.includes('/login')

  const showDropdown = isUserPortal || isAdminPortal

  if (status !== 'authenticated') {
    return (
      <Link href="/login">
        <Button
          disableHoverShadow
          color="bg-green-portal"
          hoverColor="hover:bg-[#4b9a72]"
          additionalStyles="group"
          font="font-ttHovesMedium"
          borderRadius="rounded-[100px]"
          inline
          padding="px-5 py-[2px]"
          fontSize="text-[16px]"
        >
          Customer Portal
        </Button>
      </Link>
    )
  }

  const name =
    String(session?.user?.name).split(' ').length > 1
      ? session?.user?.name?.split(' ')[0]
      : session?.user?.name

  return (
    <div className="relative group">
      <Button
        disableHoverShadow
        color="bg-green-portal"
        hoverColor="hover:bg-[#4b9a72]"
        additionalStyles="group"
        font="font-ttHovesMedium"
        borderRadius="rounded-[100px]"
        inline
        padding="px-5 py-[2px]"
        fontSize="text-[16px]"
        handleClick={() => {
          if (
            router.pathname === '/portal/account' ||
            router.pathname === '/portal/admin'
          )
            return
          router.push(
            `${
              status
                ? `${
                    session?.user.role === UserRoleEnum.NOPK_ADMIN
                      ? '/portal/admin'
                      : '/portal/account'
                  }`
                : '/login'
            }`
          )
        }}
        onFocus={() => {
          setDropdownOpen(true)
          setHidden(false)
        }}
        onBlur={() => {
          setDropdownOpen(false)
          setHidden(true)
        }}
      >
        {isPortal
          ? `${timeOfDay} ${name}`
          : status === 'authenticated' && !isUserPortal && !isLogin
          ? 'My Account'
          : 'Customer Portal'}
      </Button>

      {showDropdown ? (
        <nav
          className={`absolute flex-col transition-all duration-500 transform -translate-x-[59%] bg-transparent rounded-md group-hover:flex top-5 left-1/2 ${
            dropdownOpen ? 'flex' : 'hidden'
          } ${hidden ? ` group-focus-within:grid` : ''}`}
        >
          <div className="h-1 px-16 py-2 bg-transparent" />
          <div className="flex flex-col w-[240px] px-2 py-2 bg-white border border-gray-100 rounded-md shadow-dropdown text-base">
            {session?.user.isAdmin && !isAdminPortal && (
              <>
                <Link
                  href="/portal/account/invite-users"
                  className="pl-[15px] py-[6px] hover:bg-gray-light rounded-md  duration-300 whitespace-nowrap font-ttHovesMedium  focus:text-nptDGreen hover:text-nptDGreen text-left text-[14px] text-black-text"
                >
                  Invite Users
                </Link>

                {/* <Link
                  href="/portal/account/manage-users"
                  className="my-3 duration-300 whitespace-nowrap font-ttHovesMedium text-nptAGreen focus:text-nptDGreen hover:text-nptDGreen"
                >
                  Manage Users
                </Link> */}
              </>
            )}

            {isAdminPortal && (
              <button
                aria-hidden="true"
                onClick={(e: any) => {
                  e.preventDefault()
                  setReportOpen(true)
                }}
                className="pl-[15px] py-[6px] hover:bg-gray-light rounded-md  duration-300 cursor-pointer whitespace-nowrap font-ttHovesMedium  focus:text-nptDGreen hover:text-nptDGreen text-left  text-[14px] text-black-text"
              >
                Create Report
              </button>
            )}

            <button
              aria-hidden="true"
              onClick={() => {
                signOut({ callbackUrl: '/login' })
              }}
              className="pl-[15px] py-[6px] hover:bg-gray-light rounded-md  duration-300 cursor-pointer whitespace-nowrap font-ttHovesMedium  focus:text-nptDGreen hover:text-nptDGreen text-left  text-[14px] text-black-text"
            >
              Logout
            </button>
          </div>
        </nav>
      ) : null}
    </div>
  )
}

export default AccountNavItem
