import React from 'react'
import { attributes } from '../content/global-footer.md'
import Link from 'next/link'

const Footer: React.FunctionComponent = () => {
  const {
    footerHeadline,
    corporateFax,
    corporateTel,
    corporateEmail,
    corporateAddress,
    mapUrl,
    salesTel,
    helpTel,
    linkedInUrl,
    linkedInText,
    youTubeUrl,
    youTubeText,
  } = attributes

  return (
    <footer className="text-white md:text-nptBase bg-green-classic">
      <div className="p-4 pt-10 m-auto max-w-screen-2xl lg:p-10 lg:pt-14 lg:pb-0 lg:grid lg:grid-cols-3">
        <div>
          <h1 className="max-w-sm pr-4 mb-[73px] lg:mb-14 text-2xl uppercase leading-bigTitle font-ttHovesMedium">
            {footerHeadline}
          </h1>

          <div className="mb-14 lg:mb-36 xl:w-[80%]">
            <p className="mb-5 lg:mb-4">
              <a
                href={linkedInUrl}
                target="_blank"
                rel="noreferrer"
                className="flex items-center space-x-5 lg:gap-x-1"
              >
                <img
                  alt="North Park Transportation on LinkedIn"
                  className="w-10"
                  src="/images/icon-linkedin.svg"
                />
                <span
                  className="text-sm text-white md:text-nptBase"
                  dangerouslySetInnerHTML={{ __html: `${linkedInText}` }}
                />
              </a>
            </p>
            <p>
              <a
                href={youTubeUrl}
                target="_blank"
                rel="noreferrer"
                className="flex items-center space-x-5 lg:gap-x-1"
              >
                <img
                  alt="North Park Transportation on YouTube"
                  className="w-10"
                  src="/images/icon-youtube.svg"
                />
                <span
                  className="text-sm text-white md:text-nptBase"
                  dangerouslySetInnerHTML={{ __html: `${youTubeText}` }}
                />
              </a>
            </p>
          </div>
        </div>

        <nav className="pl-10 md:pl-0 md:grid md:grid-cols-5 lg:col-span-2 xl:pl-6 xl:gap-x-[120px] xl:whitespace-nowrap">
          <div className="col-span-1 pl-5 mb-10 xl:pl-0 md:pl-0 lg:mb-0">
            <h2 className="mb-2 uppercase xl:mb-5 font-ttHovesMedium">
              About Us
            </h2>
            <ul className="text-white ">
              <li className="mb-3 xl:mb-5">
                <Link href="/join-the-team">Careers</Link>
              </li>
              <li className="mb-3.5 xl:mb-5">
                <Link href="/service-area">Locations</Link>
              </li>
            </ul>
          </div>

          <div className="col-span-2 pl-5 mb-12 xl:pl-0 lg:mb-0">
            <h2 className="mb-3 uppercase xl:whitespace-nowrap xl:mb-5 font-ttHovesMedium">
              Help &amp; Self Service
            </h2>
            <ul className="text-white ">
              <li className="mb-3 xl:whitespace-nowrap xl:mb-5">
                Sales: <a href={`tel:${salesTel}`}>{salesTel}</a>
              </li>
              <li className="mb-3 xl:whitespace-nowrap xl:mb-5">
                Over, Short &amp; Damaged:{' '}
                <a href={`tel:${helpTel}`}>{helpTel}</a>
              </li>
              <li className="mb-3 xl:whitespace-nowrap xl:mb-5">
                <Link href="/">Track Orders</Link>
              </li>
              <li className="mb-3 xl:whitespace-nowrap xl:mb-5">
                <Link href="/get-forms">Get Forms</Link>
              </li>
            </ul>
          </div>

          <div className="col-span-2 pl-5 mb-32 xl:pl-0 lg:mb-0">
            <h2 className="mb-3.5 xl:mb-5 font-ttHovesMedium uppercase">
              Contact Us
            </h2>
            <ul className="text-white ">
              <li className="mb-3.5 xl:mb-5">Corporate Office</li>
              <li className="mb-3.5 xl:mb-5">
                <a href={mapUrl} target="_blank" rel="noreferrer">
                  {corporateAddress}
                </a>
              </li>
              <li className="mb-3.5 xl:mb-5">
                <a href={`tel:${corporateTel}`}>{corporateTel}</a>
              </li>
              <li className="mb-3.5 xl:mb-5">{corporateFax} (fax)</li>
              <li className="mb-3.5 xl:mb-5">
                <a href={`mailto:${corporateEmail}`}>{corporateEmail}</a>
              </li>
            </ul>
          </div>
        </nav>

        <div className="col-span-5 text-white md:flex xl:justify-between">
          <ul className="grid grid-cols-2 mb-6 md:gap-16 md:flex md:order-2 md:text-right xl:gap-x-[340px]">
            <li>
              <Link href="/terms-and-conditions">Terms &amp; Conditions</Link>
            </li>
            <li className="text-right xl:self-end">
              <Link href="/privacy">Privacy</Link>
            </li>
          </ul>
          <p className="col-span-1 text-center xl:flex md:flex-1 xl:flex-initial md:order-1 md:text-left">
            &copy; {new Date().getFullYear()} North Park Transportation Company,
            All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
