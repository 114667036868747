import React from 'react'

export interface IconProps {
  height?: string
  width?: string
  additionalClasses?: string
  color?: string
  bgColor?: string
}

const TruckIcon: React.FC<IconProps> = ({
  height = 'h-6',
  width = 'w-6',
  additionalClasses = '',
  color = 'currentColor',
  bgColor = 'bg-transparent',
}) => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${height} ${width} ${additionalClasses}`}
    >
      <circle cx="12" cy="12" r="12" fill={bgColor} />
      <path
        d="M4.6 15.975C3.78333 15.975 3.0875 15.6875 2.5125 15.1125C1.9375 14.5375 1.65 13.8417 1.65 13.025H0V1.50002C0 1.10002 0.15 0.750015 0.45 0.450015C0.75 0.150015 1.1 1.52588e-05 1.5 1.52588e-05H15.975V4.17502H18.6L22 8.70002V13.025H20.225C20.225 13.8417 19.9375 14.5375 19.3625 15.1125C18.7875 15.6875 18.0917 15.975 17.275 15.975C16.4583 15.975 15.7625 15.6875 15.1875 15.1125C14.6125 14.5375 14.325 13.8417 14.325 13.025H7.55C7.55 13.8417 7.2625 14.5375 6.6875 15.1125C6.1125 15.6875 5.41667 15.975 4.6 15.975ZM4.6 14.475C5 14.475 5.34167 14.3333 5.625 14.05C5.90833 13.7667 6.05 13.425 6.05 13.025C6.05 12.625 5.90833 12.2833 5.625 12C5.34167 11.7167 5 11.575 4.6 11.575C4.2 11.575 3.85833 11.7167 3.575 12C3.29167 12.2833 3.15 12.625 3.15 13.025C3.15 13.425 3.29167 13.7667 3.575 14.05C3.85833 14.3333 4.2 14.475 4.6 14.475ZM17.275 14.475C17.675 14.475 18.0167 14.3333 18.3 14.05C18.5833 13.7667 18.725 13.425 18.725 13.025C18.725 12.625 18.5833 12.2833 18.3 12C18.0167 11.7167 17.675 11.575 17.275 11.575C16.875 11.575 16.5333 11.7167 16.25 12C15.9667 12.2833 15.825 12.625 15.825 13.025C15.825 13.425 15.9667 13.7667 16.25 14.05C16.5333 14.3333 16.875 14.475 17.275 14.475ZM15.975 9.37502H20.625L17.85 5.67502H15.975V9.37502Z"
        fill={color}
      />
    </svg>
  )
}

export default TruckIcon
