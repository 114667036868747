import { AccountSearchResultProps } from '@/libs/types'
import AccountSearchResults from './AccountSearchResults'
import SearchField from './AccountSearchField'

interface AccountSearchProps {
  searchQuery: string
  searchResults: AccountSearchResultProps[]
  onChangeCallback: any
  inputPlaceholder: string
  clearSearch: () => void
  loading: boolean
  isNavSearch?: boolean
}

export const AccountSearch = ({
  searchQuery,
  searchResults,
  inputPlaceholder,
  onChangeCallback,
  clearSearch,
  loading,
  isNavSearch,
}: AccountSearchProps) => {
  return (
    <section className={`${isNavSearch ? 'mb-4' : 'mb-10'} md:w-2/3`}>
      <div
        id="account-search"
        className={`relative md:w-[552px] border border-gray-mediumLight rounded-md mt-4 ${
          searchResults?.length ? 'shadow-lg border' : 'shadow-none'
        }`}
        data-component="account-search"
      >
        <SearchField
          searchQuery={searchQuery}
          inputPlaceholder={inputPlaceholder}
          onChangeCallback={onChangeCallback}
          clearSearch={clearSearch}
          hasResults={!!searchResults?.length}
          isNavSearch={isNavSearch}
        />
        <AccountSearchResults
          searchCharacterCount={searchQuery.length}
          searchResults={searchResults}
          loading={loading}
          isNavSearch={isNavSearch}
          clearSearch={clearSearch}
        />
      </div>
    </section>
  )
}
