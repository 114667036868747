import React, { useState } from 'react'

interface ApiResponseProps {
  emsgozp: string
  errozp: string
  emsgdzp: string
  errdzp: string
  worgtel: string
  worgadd: string
  worgcty: string
  wozip5: string
  wdestel: string
  wdesadd: string
  wdescty: string
  wdzip5: string
  errors: string
}

interface VerifyZipDataFormContextProps {
  verifyZipDataFormApiResponse: ApiResponseProps
  setVerifyZipDataFormApiResponse: (verifyZipDataFormApiResponse: any) => void
}

export const defaultVerifyZipDataFormApiResponse = {
  emsgozp: '',
  errozp: '',
  emsgdzp: '',
  errdzp: '',
  worgtel: '',
  worgadd: '',
  worgcty: '',
  wozip5: '',
  wdestel: '',
  wdesadd: '',
  wdescty: '',
  wdzip5: '',
  errors: '',
}

export const VerifyZipDataFormContext =
  React.createContext<VerifyZipDataFormContextProps>({
    verifyZipDataFormApiResponse: defaultVerifyZipDataFormApiResponse,
    setVerifyZipDataFormApiResponse: (verifyZipDataFormApiResponse) => {
      return verifyZipDataFormApiResponse
    },
  })

interface VerifyZipDataFormProviderProps {
  children: React.ReactNode
}

export const VerifyZipDataFormProvider = ({
  children,
}: VerifyZipDataFormProviderProps) => {
  const [verifyZipDataFormApiResponse, setVerifyZipDataFormApiResponse] =
    useState(defaultVerifyZipDataFormApiResponse)

  return (
    <VerifyZipDataFormContext.Provider
      value={{ verifyZipDataFormApiResponse, setVerifyZipDataFormApiResponse }}
    >
      {children}
    </VerifyZipDataFormContext.Provider>
  )
}
